import i18n from '@/i18n';
import { isValidDate } from '@/state/helpers';

/* eslint-disable no-useless-escape */
function removeWhitespace(valueForm) {
    return valueForm.replace(/\s+/g, ' ').trim();
}

function regexNumber(zipCode) {
    return zipCode.replace(/-{2,}/g, '-').replace(/[^0-9-]/g, '');
}

function regexUrl(url) {
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    return expression.test(url);
}

function truncateText(text) {
    if (text.length > 100) {
        return text.slice(0, 100) + '...';
    }
    return text;
}

function renderLineBreaks(text) {
    return text?.replace(/\n/g, '<br>');
}

function isNumber(evt) {
    evt = evt ? evt : window.event;
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
    } else {
        return true;
    }
}

function formatDateJP(dateString) {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${year}${i18n.t('text.year')}${month}${i18n.t('text.month')}${day}${i18n.t('text.day')}`;
}

function getDateFromFullDate(fullDate) {
    let day = fullDate.split('-')[2];
    let month = fullDate.split('-')[1];
    let year = fullDate.split('-')[0];
    return isValidDate(day + '/' + month + '/' + year);
}

function tranferShortCode(dataShortCode, replacements) {
    let result = dataShortCode;
    if (!result) return '';
    return result.replace(/《(.*?)》/g, (match, p1) => {
        return replacements[p1] || '';
    });
}

function convertTagsToHashTags(tags) {
    if (tags.length <= 0) return '';
    return tags.map((tag) => `#${tag}`);
}

function isValidEmail(email) {
    // Regular expression for validating an email address
    const gmailRegex = /^[^\s@]+@gmail\.com$/;

    // Test the email against the regex
    return gmailRegex.test(email);
}

export {
    removeWhitespace,
    regexNumber,
    regexUrl,
    truncateText,
    renderLineBreaks,
    isNumber,
    formatDateJP,
    getDateFromFullDate,
    tranferShortCode,
    convertTagsToHashTags,
    isValidEmail
};
